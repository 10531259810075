<template>
  <div class="small-card">
    <div class="small-card__top">
      <div class="small-card__top-col">
        <span class="small-card__wholesale" v-if="data.in_wholesale"> Оптом </span>
        <span class="small-card__retail" v-if="data.in_wholesale"> Розница </span>
      </div>
    </div>
    <router-link
      v-if="data.title"
      :to="{ name: 'product', params: { id: data.id, link: data.link || (data.parent && data.parent.link) } }"
      class="small-card__image-container"
    >
      <ImgComponent :head_img="data | item_img" width="500" height="500" class="small-card__image" />
      <button
        :class="{ 'small-card__favorite--active': inFavorites }"
        type="button"
        class="small-card__favorite"
        @click.prevent="addToFavorites"
        title="В избранное"
      >
        <FileIconComponent v-if="loading" name="loading" />
        <RemixIconComponent v-else category="Health" name="heart-3-line" />
      </button>
    </router-link>
    <div class="small-card__title">
      <router-link
        v-if="data.brand && data.brand.title"
        class="small-card__company"
        :to="{ name: 'catalog', query: { brand_id: data.brand.id, page_title: data.brand.title } }"
      >
        {{ data.brand.title }}
      </router-link>
      <span v-if="data.brand && data.brand.title"> / </span>
      <router-link
        v-if="data.title"
        :to="{
          name: 'product',
          params: { id: data.id, link: data.link || (data.parent && data.parent.link) },
        }"
      >
        {{ data.title }}
      </router-link>
    </div>
    <router-link
      :to="{ name: 'product', params: { id: data.id, link: data.link || (data.parent && data.parent.link) } }"
      class="small-card__prices"
      v-if="data.final_price"
    >
      <span class="small-card__price">
        {{ data.final_price | share_price(data) | price_from_dollar($store.state) }}
      </span>
      <span v-if="data.join_share && data.join_share.share" class="small-card__old-price">
        {{ data.final_price | price_from_dollar($store.state) }}
      </span>
    </router-link>
  </div>
</template>

<script>
import RemixIconComponent from "components/RemixIconComponent.vue";
import LoginModal from "components/modals/components/LoginModal.vue";
import FileIconComponent from "components/FileIconComponent.vue";

export default {
  name: "ProductSmallCardComponent",
  props: {
    data: Object,
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    inFavorites() {
      return this.$store.state.auth.favorites.includes(this.data.id);
    },
    isRegistered() {
      return !!this.$store.state.apollo_token;
    },
  },
  methods: {
    async addToFavorites() {
      if (this.isRegistered) {
        let index = this.$store.state.auth.favorites.indexOf(this.data.id);
        if (index !== -1) {
          this.$store.state.auth.favorites.splice(index, 1);
        } else {
          this.$store.state.auth.favorites.push(this.data.id);
        }
        await this.$store.dispatch("FAVORITE_CREATE_OR_DELETE", {
          apollo: this.$apollo,
          variables: {
            item_id: this.data.id,
          },
        });
      } else {
        this.showLoginModal();
      }
    },
    showLoginModal() {
      this.$store.state._modals.push({
        component: LoginModal,
        options: {},
      });
    },
  },
  components: { FileIconComponent, RemixIconComponent },
};
</script>

<style lang="stylus">
.small-card {
  background: var(--white);
  border-radius: var(--main_radius);
  border: 1px solid var(--blue-400);
  display inline-flex
  flex-direction column
  gap 16px
  width 100%
  height 100%
  padding 8px
  position relative

  &__top {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 8px;

    &-col {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      gap: 4px;
    }
  }

  &__wholesale
  &__retail {
    background var(--green)
    border-radius: 4px;
    font-weight: normal;
    font-size: 0.75em;
    line-height: 16px;
    padding 4px
    color: var(--white);
    z-index 1
  }

  &__retail {
    background var(--accent)
  }

  &__image-container {
    height 200px
    position relative
    border-radius var(--main_radius)
    overflow hidden
  }

  &__image {
    width 100%
    height 100%
    object-fit contain
    object-position center
  }

  &__favorite {
    display flex
    absolute right 8px top 8px
    border none
    background none
    cursor pointer
    border-radius var(--small_radius)
    padding 5px
    transition var(--transition)
    background var(--blue-500-o5)

    &:hover {
      background var(--blue-500)
    }

    &.small-card__favorite--active {
      .icon svg path {
        fill var(--accent)
      }
    }

    .icon {
      width 20px
      height 20px

      svg {
        path {
          fill var(--body-color-muted)
        }

        circle {
          stroke var(--body-color-muted)
        }
      }
    }
  }

  &__title {
    & * {
      font-weight: normal;
      font-size: 0.875em;
      line-height: 20px;
      color var(--gray-300)
    }
  }

  &__company {
    font-weight: normal;
    font-size: 0.875em;
    line-height: 20px;
    color: var(--body-color-muted);
  }

  &__prices {
    display flex
    flex-direction column
    align-items flex-start
    justify-content center
    padding 0 8px 8px
    margin-top auto
  }

  &__price {
    font-weight: 600;
    font-size: 1.125em;
    line-height: 24px;
    color: var(--gray-900);
  }

  &__old-price {
    font-weight: normal;
    font-size: 0.75em;
    line-height: 18px;
    display: flex;
    align-items: center;
    text-decoration-line: line-through;
    color: var(--gray-300);
  }
}
</style>
